/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "../pages/About";
import SingleBlog from "../pages/SingleBlog";
import Blogs from "../pages/Blogs";
import Contact from "../pages/Contact";
import Gallery from "../pages/Gallery";
import Gallery2 from "../pages/Gallery2";
import Menu from "../pages/Menu";
import News from "../pages/News";
import ProAdvantage from "../pages/ProAdvantage";
import Shipping from "../pages/Shipping";
import Testimonial from "../pages/Testimonial";
import RequestDemo from "../pages/RequestDemo";
import Gal from "../pages/Gal";
import Home from "../pages/Home";
import Events from "../pages/Events";
import OurTeam from "../pages/OurTeam";
import { useMediaQuery } from "@mui/material";

const Navigation = () => {
  //   window.onload = () => {
  //     // Create aliases for document.querySelector and document.querySelectorAll
  //     const $ = document.querySelector.bind(document);
  //     const $All = document.querySelectorAll.bind(document);

  //     // Add a click event listener to the element with id 'menu'
  //     $("#menu").onclick = () => {
  //       // Toggle the 'rotate' class on the element with id 'menu'
  //       $("#menu").classList.toggle("rotate");

  //       // Toggle the 'transform' class on elements with class 'nav-page1' and 'nav-page2'
  //       $(".nav-page1").classList.toggle("transform");
  //       $(".nav-page2").classList.toggle("transform");

  //       // Toggle the 'rotate1' and 'rotate2' classes on elements with class 'menu-line1' and 'menu-line2'
  //       $(".menu-line1").classList.toggle("rotate1");
  //       $(".menu-line2").classList.toggle("rotate2");
  //     };
  //   };
  // }, []);

  const isLarge = useMediaQuery("(max-width:1100px)");
  const isMedium = useMediaQuery("(max-width:950px)");
  const isSmall = useMediaQuery("(max-width:767px)");
  return (
    <Router scrollBehavior="auto">
      <img
        src={require("../assets/images/home/wechat.png")}
        style={{
          position: "fixed",
          bottom: "17.5%",
          right: 50,
          zIndex: 9999999,
          cursor: "pointer",
          height: isSmall ? "30px" : isMedium ? "37.5px" : "40px",
          width: isSmall ? "125px" : isMedium ? "150x" : "175px",
        }}
        onClick={() =>
          window.open("https://u.wechat.com/kBQdwyjcDK8YiApn89whXXo", "_blank")
        }
      />
      <img
        src={require("../assets/images/home/whatsapp.png")}
        style={{
          cursor: "pointer",
          position: "fixed",
          bottom: "10%",
          right: 50,
          zIndex: 9999999,
          height: isSmall ? "30px" : isMedium ? "37.5px" : "40px",
          width: isSmall ? "125px" : isMedium ? "150x" : "175px",
        }}
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send/?phone=14372147577&text=Hello%2C+I+saw+the+network+website.+I+have+a+few+questions+about+it%2C+are+you+available+to+chat%3F&type=phone_number&app_absent=0",
            "_blank"
          )
        }
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/news/:id" element={<SingleBlog />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/gallery" element={<Gal />} />
        <Route path="/conference-gallery/:id" element={<Gallery />} />
        <Route path="/all-gallery/:conferenceId/:id" element={<Gallery2 />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/pro-advantage" element={<ProAdvantage />} />
        <Route path="/blog/:slug" element={<Shipping />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/news" element={<News />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/events" element={<Events />} />
        <Route path="/team" element={<OurTeam />} />
        {/* <Route path="/test" element={<Test />} /> */}
      </Routes>
    </Router>
  );
};

export default Navigation;
